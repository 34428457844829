@font-face {
    font-family: "LoveloBlack";
    src: url('../fonts/Lovelo/Lovelo-Black.eot');
    src: url('../fonts/Lovelo/Lovelo-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lovelo/Lovelo-Black.woff2') format('woff2'),
    url('../fonts/Lovelo/Lovelo-Black.woff') format('woff'),
    url('../fonts/Lovelo/Lovelo-Black.ttf') format('truetype');
}

@font-face {
    font-family: "LoveloLineBold";
    src: url('../fonts/Lovelo/Lovelo-LineBold.eot');
    src: url('../fonts/Lovelo/Lovelo-LineBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lovelo/Lovelo-LineBold.woff2') format('woff2'),
    url('../fonts/Lovelo/Lovelo-LineBold.woff') format('woff'),
    url('../fonts/Lovelo/Lovelo-LineBold.ttf') format('truetype');
}

@font-face {
    font-family: "LoveloLineLight";
    src: url('../fonts/Lovelo/Lovelo-LineLight.eot');
    src: url('../fonts/Lovelo/Lovelo-LineLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Lovelo/Lovelo-LineLight.woff2') format('woff2'),
    url('../fonts/Lovelo/Lovelo-LineLight.woff') format('woff'),
    url('../fonts/Lovelo/Lovelo-LineLight.ttf') format('truetype');
}

@font-face {
    font-family: "xmasFont";
    src: url("../fonts/xmas.otf");
}